$primaryPeacock: #018e91;
$secondary: #06c8d1;
$accentLight: #ff9981;
$accentDark: #c91703;
$lightBlue: #adfbff;
$lightYellow: #fffecb;
$lightRed: #ffdbd2;
$dark: #859797;
$light: #d3d7db;
$background: #f3f4fa;
$lightBackground: #fafbff;
$lightText: #758585;
$lightGreen: #d8e3eb;
$lightBorder: #8896a41a;
$lightBorderBoxShadow: #7090b01a;
$highlight: #f7f8fe;
$accentGreen: #c7e1c9;
$inputBorderColor: #dadded;
// New palette
// Lets start defining and then decide on the naming once we get to the final design
$newPrimaryLight: #e6f4f4;
$newPrimaryDark: #017c7e;
$newPrimaryDarker: #016366;
$black: #212429;
$backgroundGrey: #f2f2f2;
$iconGrey: #b5b5b5;
$contrastGrey: #777575;
$dividerColor: #b5b5b5;
$textGrey: #707070;
$hrGrey: #dee6eb;
$secondaryTextGrey: #6c6c6c;
$darkGrey: #606060;
$scrollbarGrey: #2e2f2f80; // same as $text but with alpha = 0.5
$white: #ffffff;
$cyberGrape: #4a4177;
$cyberGrapeLight: #edecf1;
$cyberGrapeLight1: #a5a0bb;
$cyberGrapeDark: #3a3264;
$disabledGray: #acb5bd;
$grass: #38976a;
$grassLight: #ebf5f0;
$tableHeaderGrey: #495057;
$tableBorderGrey: #dfe0eb;
$paginationGrey: #9fa2b4;
$paginationDarkGrey: #4b506d;
$cardBackgroundGrey: #f8f9fc;
$coral: #e0311a;
$logoRed: #f15d4a;
$coralLight: #feefed;
$secondarySky: #365c94;
$secondarySkyLight: #ebeff4;
$grey3: #acb5bd;
$plum: #4a4177;
$plumLight: #edecf1;
$pumpkinPrimary: #fb7e21;
$pumpkinLight: #fff2e9;
$peacockDark: #017c7e;
$peacockDarker: #016366;
$grey3: #6e7681;
$ripplingYellow: #fdb71c;

@mixin setBgColor($bg, $textColor, $outlineOnly: false, $border: null, $hover: null, $disabled: null, $focused: null) {
  color: $textColor;
  text-transform: uppercase;
  border-radius: 8px;
  padding: 12px 24px 12px 24px;
  @if $outlineOnly {
    border: 1px solid $bg;
    box-sizing: border-box;
    @if $hover {
      &:hover {
        background-color: $hover;
      }
    } @else {
      &:hover {
        border-color: fade($bg, 80%);
      }
    }
    @if $focused {
      &:active {
        background: $focused;
      }
    }
    @if $disabled {
      &.disabled {
        color: $disabled;
        cursor: auto;
        pointer-events: none;
      }
    } @else {
      &.disabled {
        border-color: desaturate($bg, 80%);
        color: desaturate($textColor, 80%);
        opacity: 0.4;
        cursor: auto;
        pointer-events: none;
      }
    }
  } @else if $border {
    background: $bg;
    border: 1px solid $border;
    @if $hover {
      &:hover {
        background-color: $hover;
      }
    } @else {
      &:hover {
        border-color: fade($bg, 80%);
      }
    }
    @if $focused {
      &:active {
        background: $focused;
      }
    }
    @if $disabled {
      &.disabled {
        color: $disabled;
        pointer-events: none;
        cursor: auto;
      }
    } @else {
      &.disabled {
        background: desaturate($bg, 30%);
        opacity: 0.4;
        cursor: auto;
        pointer-events: none;
      }
    }
  } @else {
    background: $bg;
    @if $hover {
      &:hover {
        background-color: $hover;
      }
    } @else {
      &:hover {
        border-color: fade($bg, 80%);
      }
    }
    @if $focused {
      &:active {
        background: $focused;
      }
    }
    @if $disabled {
      &.disabled {
        color: $disabled;
        cursor: auto;
        pointer-events: none;
      }
    } @else {
      &.disabled {
        background: desaturate($bg, 30%);
        opacity: 0.4;
        cursor: auto;
        pointer-events: none;
      }
    }
  }

  .icon {
    fill: currentColor;
  }
  .feather-more-vertical {
    fill: $lightText;
  }
  // When plain button is set use color prop for text
  &.plain {
    background: transparent;
    border-color: transparent;
    color: $bg;
    padding: 0;
    &:hover {
      color: fade($bg, 80%);
    }
    &.disabled {
      color: desaturate($bg, 80%);
    }
  }
}
